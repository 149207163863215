<template>
  <div v-click-outside="togglePopover" class="vms-popover">
    <div class="popover-container vd-full-height">
      <div v-if="!useLabelSlot">
        <div
          v-if="showIcon"
          :id="tooltipId"
          :class="[tooltipIcon, customTooltipClass]"
          tabindex="0"
        ></div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-else :id="tooltipId" tabindex="0" v-html="tooltipLabel"></div>
      </div>

      <slot name="popover-label"></slot>

      <b-popover
        class="vd-box-shadow"
        :target="tooltipId"
        :triggers="tooltipTrigger"
        :placement="tooltipPlacement"
        :show.sync="isPopoverVisible"
        :custom-class="popoverCustomClass"
      >
        <slot name="popover-body">
          <template>
            <div v-if="tooltipText" class="popover-wrapper--content vd-black">
              <div class="content">
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="tooltipText"></div>
              </div>
            </div>
          </template>
        </slot>
      </b-popover>
    </div>
  </div>
</template>

<script>
import { BPopover } from 'bootstrap-vue'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    BPopover,
  },

  directives: {
    ClickOutside,
  },

  props: {
    tooltipId: {
      type: String,
      required: true,
      default: '',
    },

    tooltipText: {
      type: String,
      required: false,
      default: '',
    },

    tooltipPlacement: {
      type: String,
      required: false,
      default: 'bottom',
    },

    tooltipTrigger: {
      type: String,
      required: false,
      default: 'hover',
    },

    customTooltipClass: {
      type: String,
      required: false,
      default: '',
    },

    popoverCustomClass: {
      type: String,
      required: false,
      default: '',
    },

    tooltipIcon: {
      type: String,
      required: false,
      default: 'vd-icon-tooltip',
    },

    useLabelSlot: {
      type: Boolean,
      required: false,
      default: false,
    },

    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },

    tooltipLabel: {
      type: String,
      required: false,
      default: '',
    },

    closeOnClickOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      isPopoverVisible: false,
    }
  },

  methods: {
    togglePopover() {
      // Allow popover close on click outside
      if (this.isPopoverVisible && !this.closeOnClickOnly) {
        this.isPopoverVisible = false
      }
    },
  },
}
</script>
